import React from 'react'

const Configuration = React.lazy(() => import('./views/Configuration'))
const CallHistory = React.lazy(() => import('./views/CallHistory'))
const Landing = React.lazy(() => import('./views/Landing'))
const Error = React.lazy(() => import('./views/Error'))

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/Config', name: 'Configuration', element: Configuration },
  { path: '/CallHistory', name: 'Missed Calls', element: CallHistory, exact: true },
  { path: '/Landing', name: 'Landing', element: Landing, exact: true },
  { path: '/Error', name: 'Error', element: Error, exact: true },
]

export default routes
