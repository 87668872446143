import AppContent from "../components/AppContent";

const AnonymousLayout = () => {
  return (
    <div>
      <AppContent />
    </div>
  );
};

export default AnonymousLayout;
