import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import {
  FluentProvider,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  Spinner,
  tokens,
} from "@fluentui/react-components";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import { TeamsFxContext } from "./TeamsFxContext";
import config from "../common/config";
import AnonymousLayout from "../layout/AnonymousLayout";
import { GraphEndpoint, Providers } from "@microsoft/mgt-element";
import { TeamsUserCredential, TeamsUserCredentialAuthConfig } from "@microsoft/teamsfx";
import { TeamsFxProvider } from "@microsoft/mgt-teamsfx-provider";

const DefaultLayout = React.lazy(() => import("../layout/DefaultLayout"));

export default function App() {

  const { loading, theme, themeString, teamsUserCredential } = useTeamsUserCredential({
    initiateLoginEndpoint: config.initiateLoginEndpoint!,
    clientId: config.clientId!,
  });

  const baseURL: GraphEndpoint = config.graphEndpint as GraphEndpoint;

  const authConfig: TeamsUserCredentialAuthConfig = {
    clientId: config.clientId,
    initiateLoginEndpoint: config.initiateLoginEndpoint
  };

  const credential = new TeamsUserCredential(authConfig);
  const provider = new TeamsFxProvider(credential, config.defaultScopes, baseURL);

  Providers.globalProvider = provider;

  return (
    <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential }}>
      <FluentProvider
        theme={
          themeString === "dark" ? {
            ...teamsDarkTheme,
            fontFamilyBase: "'Inter', BlinkMacSystemFont, Helvetica, Arial, sans-serif"
          } : themeString === "contrast" ? {
            ...teamsHighContrastTheme,
            fontFamilyBase: "'Inter', BlinkMacSystemFont, Helvetica, Arial, sans-serif"
          } : {
            ...teamsLightTheme,
            fontFamilyBase: "'Inter', BlinkMacSystemFont, Helvetica, Arial, sans-serif"
          }
        }
        style={{ fontFamily: tokens.fontFamilyBase, background: "transparent", padding: "20px 20px 10px 15px" }}
      >
        <BrowserRouter>
          {loading ? (
            <Spinner style={{ margin: 100 }} />
          ) : (
            <Routes>
              <Route path="/Landing" element={<AnonymousLayout />} />
              <Route path="*" element={<DefaultLayout />} />
            </Routes>
          )}
        </BrowserRouter>
      </FluentProvider>
    </TeamsFxContext.Provider>
  );
}