const config = {
  initiateLoginEndpoint:
    `${process.env.REACT_APP_TEAMS_APPURL}/auth-start`,
  clientId: process.env.REACT_APP_CLIENTID, //"c42b0067-bfb5-4a33-8f9f-5f1ef2dfceb8",
  apiEndpoint: process.env.REACT_APP_API_ENDPOINT, //"https://api.easyplatform.app/",
  graphEndpint: "https://graph.microsoft.com",
  adminLoginUrl: process.env.REACT_APP_API_ENDPOINT, //"https://admin.easyplatform.app/",
  teamsAppUrl: process.env.REACT_APP_TEAMS_APPURL, //"https://teams.easycallreport.easyplatform.app",
  scopes: [
    `api://${process.env.REACT_APP_TEAMS_APPURL}/${process.env.REACT_APP_CLIENTID}/access_as_user`,
  ],
  defaultScopes: [],
  easyUserScopes: ['Presence.Read.All', 'User.Read.All'],
  callingRingtone:
    "https://aws-easycalling-bot-prod.azurewebsites.net/audio/teams_incoming_call_sound.mp3",
};

export default config;
